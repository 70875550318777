import cn from 'clsx'
import Link from 'next/link'

interface ButtonProps {
  children?: React.ReactNode
  /* eslint-disable-next-line */
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  icon?: React.ReactNode
  primary?: boolean
  secondary?: boolean
  href?: string
  iconPosition?: 'left' | 'right'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  className = '',
  icon,
  primary,
  secondary,
  href,
  iconPosition = 'left',
  type = 'button',
  disabled = false,
  ...props
}: ButtonProps) => {
  if (href) {
    return (
      <Link
        {...props}
        href={href}
        className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}>
        {icon && <span className='text-sm'>{icon}</span>} {children}
      </Link>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={cn('btn', primary && 'btn-primary', secondary && 'btn-secondary', className)}
      {...props}>
      {icon && iconPosition === 'left' && <span className='text-sm'>{icon}</span>} {children}
      {icon && iconPosition === 'right' && <span className='text-sm'>{icon}</span>}
    </button>
  )
}

export default Button
