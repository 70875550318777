import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import cn from 'clsx'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import CoreNavLink from 'components/molecules/CoreNavLink'
import withErrorBoundary from 'components/templates/WithErrorBoundary'
import {
  ExternalLink,
  IAnalytics,
  ICalendar,
  IComments,
  ICustomers,
  IDiscount,
  IHamburgerMenu,
  IMarketing,
  IMembership,
  IOrders,
  ISettings,
  ITag,
  IVenues,
  IWaitlist,
} from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawer, setMegaMenu } from 'store/reducers'
import { getBaseUrlFor } from 'utils/Url'

const logo = '/assets/icons/icon-512.png'

const NavDrawer = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const isDrawerOpen = useSelector(s => s?.pr?.ui?.isDrawerOpen)
  const megaMenu = useSelector(s => s?.pr?.ui?.megaMenu)

  const host = useSelector(s => s?.pr?.user?.info)

  const [activeParentNav, setActiveParentNav] = useState('')

  useEffect(() => {
    dispatch(setDrawer(true))
  }, [])

  useEffect(() => {
    dispatch(setMegaMenu(false))
  }, [router.pathname])

  const toggleMenu = () => {
    dispatch(setMegaMenu(false))
    dispatch(setDrawer(!isDrawerOpen))
  }

  const NavConfig = [
    { label: 'Home', icon: <AiFillHome size={20} />, path: ROUTES.DASHBOARD },
    {
      label: 'Orders',
      icon: <IOrders size={20} />,
      path: ROUTES.ORDERS_OVERVIEW,
    },
    { label: 'Separator', path: 'uniqueKey1' },

    // Products & Services - Start
    {
      label: 'Events',
      icon: <ICalendar size={20} />,
      path: ROUTES.EVENTS,
      subNav: [
        // { label: 'All', icon: <BsCalendar2EventFill size={16} />, path: ROUTES.EVENTS },
        { label: 'My Venues', icon: <IVenues size={20} />, path: ROUTES.VENUES },
        {
          label: 'Comments',
          icon: <IComments size={18} />,
          path: ROUTES.COMMENTS,
          newFeature: true,
        },
        { label: 'Waitlists', icon: <IWaitlist size={20} />, path: ROUTES.WAITLISTS },
      ],
    },
    {
      label: 'Merch',
      icon: <ITag size={22} />,
      path: ROUTES.PRODUCTS,
      comingSoon: true,
    },
    {
      label: 'Memberships',
      icon: <IMembership size={24} />,
      path: ROUTES.MEMBERSHIPS,
      comingSoon: true,
    },
    // Products & Services - END
    { label: 'Separator', path: 'uniqueKey2' },
    { label: 'Customers', icon: <ICustomers size={20} />, path: ROUTES.GUESTS },
    { label: 'Analytics', icon: <IAnalytics size={24} />, path: ROUTES.EARNINGS },
    { label: 'Marketing', icon: <IMarketing size={20} />, path: ROUTES.PROMOTE },
    { label: 'Discounts', icon: <IDiscount size={20} />, path: ROUTES.DISCOUNTS },

    // Setting Option is Fixed at Bottom
  ]

  return (
    <nav
      className={cn(
        'fixed left-0 top-0 z-50',
        'flex h-screen flex-col justify-between bg-neutral p-4 lg:flex',
        megaMenu ? 'w-full overflow-x-hidden shadow-xl' : 'hidden',
        isDrawerOpen ? 'lg:w-72' : 'lg:w-20',
        'custom-transition-a',
        '!dark:bg-base-200 bg-neutral'
      )}>
      <div className='flex flex-row items-center gap-4' key='DrawerHeader'>
        <Button
          className={cn(
            'hidden md:block',
            'btn w-fit place-self-center border-none bg-transparent text-white'
          )}
          onClick={toggleMenu}>
          <IHamburgerMenu size={24} />
        </Button>

        <div className={cn('flex flex-row items-center gap-2', isDrawerOpen ? 'block' : 'hidden')}>
          <Image src={logo} alt='Icon White' width={24} height={24} className='h-8 w-8' />
          <h1 className='flex flex-col text-xs font-thin uppercase text-white'>
            <span className='text-white'>Partners</span>
            <span className='text-white'>Studio</span>
          </h1>
        </div>
      </div>

      <a
        key='HostProfile'
        target='_blank'
        href={
          getBaseUrlFor('marketplace') +
          (host?.username
            ? ROUTES.HOST_HOMEPAGE.replace(':username', host?.username)
            : ROUTES.BOOKING_WIDGET.replace(':hostId', host?.id))
        }
        className={cn('group tooltip mt-12 flex cursor-pointer flex-col items-center')}
        data-tip='Visit your Store on Marketplace'>
        <div className='relative'>
          <Image
            src={host?.profileImage}
            alt='Host Image'
            width={96}
            height={96}
            className={cn(
              'rounded-full  group-hover:opacity-60',
              isDrawerOpen ? 'h-24 w-24' : 'h-10 w-10'
            )}
          />
          <ExternalLink
            size={24}
            className='absolute right-1/2 top-1/2 hidden -translate-y-1/2 translate-x-1/2 group-hover:block '
          />
        </div>
        {isDrawerOpen && (
          <h2 className='text-sm font-thin text-primary-content'>{host?.hostTitle}</h2>
        )}
      </a>

      <div className='z-50 flex flex-1 flex-col justify-center space-y-2' key='DrawerNav'>
        {NavConfig.map((item, index) => {
          if (item.label === 'Separator')
            return (
              <div key={item.path + index} className='h-px w-full bg-white bg-opacity-10'></div>
            )

          return (
            <div key={item.label}>
              <CoreNavLink
                href={item.path}
                icon={item.icon}
                label={item.label}
                isDrawerOpen={isDrawerOpen}
                isActive={router?.pathname.includes(item.path)}
                isNewFeature={item.newFeature}
                isComingSoon={item.comingSoon}
                onClick={() => setActiveParentNav(item.path)}
              />
              {/* SubNav */}
              {activeParentNav === item.path && item.subNav && (
                <div
                  className={cn(
                    isDrawerOpen ? 'ml-6' : 'ml-0',
                    'flex flex-col items-start justify-start space-y-2'
                  )}>
                  {item.subNav.map((subItem, subIndex) => (
                    <CoreNavLink
                      key={subItem.label + subIndex}
                      href={subItem.path}
                      icon={subItem.icon}
                      label={subItem.label}
                      isDrawerOpen={isDrawerOpen}
                      isActive={router?.pathname.includes(subItem.path)}
                      isNewFeature={subItem.newFeature}
                      isComingSoon={subItem.comingSoon}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </div>

      <CoreNavLink
        key='Settings'
        href={ROUTES.SETTINGS_PROFILE}
        icon={<ISettings size={20} />}
        label='Settings'
        isDrawerOpen={isDrawerOpen}
        isActive={router?.pathname.includes(ROUTES.SETTINGS_PROFILE)}
      />
    </nav>
  )
}
export default withErrorBoundary(NavDrawer)
