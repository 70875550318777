import withErrorBoundary from 'components/templates/WithErrorBoundary'

const GridContainer = ({ className = '', children }) => {
  return (
    <div className={`mx-2 grid grid-cols-4 gap-8 lg:mx-0 lg:grid-cols-12 lg:gap-4  ${className}`}>
      {children}
    </div>
  )
}

export default withErrorBoundary(GridContainer)
