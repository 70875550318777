import { BsPersonCircle } from '@react-icons/all-files/bs/BsPersonCircle'
import { IoChevronDownOutline } from '@react-icons/all-files/io5/IoChevronDownOutline'
import cn from 'clsx'
import Image from 'components/atoms/Image'
import VerifiedBadge from 'components/organisms/Badge/VerifiedBadge'
import {
  ExternalLink,
  IHelp,
  ISettings,
  IconEdit,
  IconHandshake,
  IconVideoCamera,
  LogIn,
  LogOut,
} from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from 'consts/Urls'
import useLogOut from 'hooks/useLogOut'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'
import { getBaseUrlFor } from 'utils/Url'

import ThemeToggler from './ThemeToggler'

const ProfileMenu = () => {
  const { logOutHandler } = useLogOut()
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)
  const profileImage = useSelector(s => s?.pr?.user?.info?.profileImage)
  const user = useSelector(s => s?.pr?.user?.info)
  const userId = useSelector(s => s?.pr?.user?.info?.id)
  const name = user?.hostTitle || `${user?.firstName} ${user?.lastName}` || user?.email
  const router = useRouter()
  const theme = useSelector(s => s?.pr?.ui?.theme)

  const PROFILE_MENU = {
    UNAUTH: [
      {
        title: 'Log In',
        icon: <LogIn size={20} />,
        path: ROUTES.LOG_IN,
      },
      {
        title: 'Sign Up',
        icon: <IconHandshake size={20} />,
        path: ROUTES.SIGN_UP,
      },
      {
        title: 'Separator',
      },
      {
        title: 'FAQs',
        icon: <IHelp size={20} />,
        path: ROUTES.FAQS,
      },
      {
        title: 'Contact',
        icon: <IconEdit size={20} />,
        path: ROUTES.CONTACT,
      },
      {
        title: 'Book a Meeting',
        icon: <IconVideoCamera size={20} />,
        onClick: () => window.open(BOOK_A_MEETING_WITH_FOUNDERS_URL, '_blank'),
      },
      {
        title: 'Separator',
      },
      // {
      //   title: 'Theme Toggler',
      // },
    ],
    AUTH_HOST: [
      // {
      //   title: 'Separator',
      // },
      {
        title: 'Settings',
        icon: <ISettings size={20} />,
        path: ROUTES.SETTINGS_PROFILE,
      },
      {
        title: 'Visit Marketplace',
        icon: <ExternalLink size={20} />,
        onClick: () =>
          window.open(
            getBaseUrlFor('marketplace') + ROUTES.BOOKING_WIDGET.replace(':hostId', userId),
            '_blank'
          ),
      },
      // {
      //   title: 'Theme Toggler',
      // },
      {
        title: 'Separator',
      },
      {
        title: 'Sign out',
        icon: <LogOut size={20} />,
        onClick: logOutHandler,
      },
    ],
  }

  let MENU

  if (!accessToken) {
    MENU = PROFILE_MENU.UNAUTH
  } else {
    MENU = PROFILE_MENU.AUTH_HOST
  }

  return (
    <div className='dropdown dropdown-end font-thin ' id='profile-menu'>
      <div role='button' tabIndex={0} className='flex cursor-pointer'>
        {accessToken ? (
          <div className='avatar'>
            {/* <IoChevronDownOutline
              className={` absolute bottom-0 right-0 z-10 rounded-3xl ${!theme === 'light' ? 'bg-white text-black' : ' bg-slate-900 text-white'}`}
              size={14}
            /> */}
            <div className='w-10 rounded-full'>
              <Image src={profileImage} alt='Profile' width={20} height={20} sizing={320} />
            </div>
          </div>
        ) : (
          <div>
            <BsPersonCircle size={27} />
            <IoChevronDownOutline
              className={` absolute -bottom-1 right-0 z-10 rounded-3xl ${theme === 'light' ? 'bg-white text-black' : ' bg-slate-900 text-white'}`}
              size={12}
            />
          </div>
        )}
      </div>

      <ul
        tabIndex={0}
        className='menu dropdown-content z-40 mt-4 min-w-60 gap-2 rounded-box bg-base-100 py-4 shadow'>
        {accessToken ? (
          <li
            onClick={() => {
              if (user?.userType === 'host') {
                router.push(ROUTES.DASHBOARD)
              }
            }}>
            <div className='flex cursor-pointer items-center justify-start gap-3'>
              <div className='avatar'>
                <div className='w-12 rounded-full'>
                  <Image src={profileImage} alt='Profile' width={20} height={20} sizing={640} />
                </div>
              </div>
              <div id='name-badge' className=' flex items-center gap-2'>
                {name && <span className='font-semibold md:flex'>{name}</span>}
                {user?.isVerified && user?.userType === 'host' && <VerifiedBadge />}
              </div>
            </div>
          </li>
        ) : (
          <>
            {/* <h3 className='flex flex-col items-center justify-center font-semibold'>Menu</h3> */}
          </>
        )}

        {MENU?.map((item, index) => {
          if (item.title === 'Separator')
            return <li key={index} className={cn('border-r border-base-300', item?.className)} />

          if (item.title === 'Theme Toggler')
            return (
              <li key='theme-toggler'>
                <ThemeToggler />
              </li>
            )

          return (
            <li
              key={item.title}
              className={item?.className}
              onClick={() => {
                document.getElementById('profile-menu').removeAttribute('open')
                if (item?.onClick) {
                  item.onClick()
                }
              }}>
              {item?.title === 'Sign out' ? (
                <div className={cn('flex h-full w-full items-center justify-center text-center')}>
                  {item?.icon}
                  <span className='font-semibold'>{item?.title}</span>
                </div>
              ) : (
                <Link
                  href={item?.path ?? '#'}
                  className={cn('flex h-full w-full items-center text-center')}>
                  {item?.icon}
                  <span>{item?.title}</span>
                </Link>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProfileMenu
