import cn from 'clsx'
import { ExternalLink } from 'consts/Icons'
import Link from 'next/link'

const CoreNavLink = ({
  href,
  icon,
  label,
  isDrawerOpen,
  isActive,
  isNewFeature,
  isComingSoon,
  isExternal,
  className,
  onClick,
}) => {
  const baseClasses = cn(
    'flex items-center justify-start rounded-full text-white',
    'custom-transition-b cursor-pointer',
    'hover:scale-105 hover:bg-secondary/50',
    isDrawerOpen ? 'px-4 py-3' : 'tooltip tooltip-right tooltip-secondary p-3',
    isActive && 'bg-secondary',
    !isActive && 'opacity-80',
    className
  )

  const content = (
    <>
      {icon}
      <span className={cn('ml-6 font-light', isDrawerOpen ? 'block' : 'lg:hidden')}>{label}</span>

      {isDrawerOpen && isNewFeature && <div className='badge badge-success ml-2'>New</div>}
      {isDrawerOpen && isComingSoon && (
        <div className='badge badge-info ml-2 overflow-hidden'>Upcoming</div>
      )}
      {isDrawerOpen && isExternal && <ExternalLink size={16} className='ml-2' />}
    </>
  )

  if (isExternal) {
    return (
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={baseClasses}
        onClick={onClick}
        data-tip={!isDrawerOpen ? label : undefined}>
        {content}
      </a>
    )
  }

  return (
    <Link
      href={href || '#'}
      className={baseClasses}
      onClick={onClick}
      data-tip={!isDrawerOpen ? label : undefined}>
      {content}
    </Link>
  )
}

export default CoreNavLink
