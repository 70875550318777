import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { BsCalendar2EventFill } from '@react-icons/all-files/bs/BsCalendar2EventFill'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { GiHamburgerMenu } from '@react-icons/all-files/gi/GiHamburgerMenu'
import { MdOutlineQrCodeScanner } from '@react-icons/all-files/md/MdOutlineQrCodeScanner'
import ROUTES from 'consts/Routes'
import { useRouter } from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMegaMenu } from 'store/reducers'

const PWAnavbar = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const megaMenu = useSelector(s => s?.pr?.ui?.megaMenu)

  const handleClick = item => {
    if (item.path) {
      router.push(item.path)
    } else if (item.onClick) {
      item.onClick()
    }
  }

  const toggleMenu = () => {
    dispatch(setMegaMenu(!megaMenu))
  }

  const NAV_HOST = [
    { label: 'Home', path: ROUTES.DASHBOARD, icon: <AiFillHome size={24} /> },
    { label: 'Events', path: ROUTES.EVENTS, icon: <BsCalendar2EventFill size={20} /> },
    {
      label: 'Scan',
      path: ROUTES.SCAN,
      icon: <MdOutlineQrCodeScanner size={24} />,
    },
    { label: 'Guests', path: ROUTES.GUESTS, icon: <FiUsers size={24} /> },
    {
      label: 'Menu',
      path: '',
      icon: <GiHamburgerMenu size={24} />,
      onClick: toggleMenu,
    },
  ]

  return (
    <div className='btm-nav z-50 bg-primary lg:hidden dark:bg-black'>
      {NAV_HOST.map(item => (
        <button
          className={router?.pathname === item.path ? 'text-secondary' : 'text-white'}
          key={item.label}
          onClick={() => handleClick(item)}>
          {item.icon}
        </button>
      ))}
    </div>
  )
}

export default PWAnavbar
