import useGlobalLoader from 'hooks/useGlobalLoader'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAggregatedDailyOrders,
  getAggregatedMonthlyOrders,
  getAggregatedYearlyOrders,
  getSpecificDateOrdersBySellerId,
  readEventsByHostId,
  readOrdersBySellerId,
  readProductsByHostId,
} from 'services/client'
import { readCampaignsByHostId } from 'services/client/seller/campaigns'
import { setEvents, setMyCampaigns, setProducts, setSaleOrders } from 'store/reducers'

// Helper function to determine the appropriate data source based on date range
const getTimeframeDuration = (startDate, endDate) => {
  if (!startDate || !endDate) return 'day'

  const diffTime = Math.abs(endDate - startDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  // Determine the appropriate data source based on time difference
  if (diffDays <= 1) {
    return 'day' // Raw orders for detailed view
  } else if (diffDays <= 31) {
    return 'daily' // Daily aggregated data for weeks or a month
  } else if (diffDays <= 366) {
    return 'monthly' // Monthly aggregated data for longer periods
  } else {
    return 'yearly' // Yearly aggregated data for very long periods
  }
}

export default function useFetcherForHost() {
  const dispatch = useDispatch()
  const hostId = useSelector(s => s?.pr?.user?.info?.id)
  const { loading } = useGlobalLoader()

  const fetchAllMyEvents = () => {
    loading.start()
    readEventsByHostId(hostId, events => {
      dispatch(setEvents(events))
      loading.remove()
    })
  }

  const fetchAllMyEventOrders = async () => {
    readOrdersBySellerId(hostId, orders => {
      dispatch(setSaleOrders(orders))
    })
  }

  const fetchMySpecificDateOrders = async (startDate, endDate) => {
    const timeframeDuration = getTimeframeDuration(startDate, endDate)

    let orders
    switch (timeframeDuration) {
      case 'day':
        // For single day or less, fetch directly from orders collection
        orders = await getSpecificDateOrdersBySellerId(hostId, startDate, endDate)
        break
      case 'daily':
        // For time periods > 1 day but <= 1 month, fetch from daily aggregated collection
        orders = await getAggregatedDailyOrders(hostId, startDate, endDate)
        break
      case 'monthly':
        // For time periods > 1 month but <= 1 year, fetch from monthly aggregated collection
        orders = await getAggregatedMonthlyOrders(hostId, startDate, endDate)
        break
      case 'yearly':
        // For time periods > 1 year, fetch from yearly aggregated collection
        orders = await getAggregatedYearlyOrders(hostId, startDate, endDate)
        break
      default:
        orders = await getSpecificDateOrdersBySellerId(hostId, startDate, endDate)
    }

    return orders
  }

  const fetchMyCampaigns = () => {
    readCampaignsByHostId(hostId, campaigns => {
      dispatch(setMyCampaigns(campaigns))
    })
    // try {
    //   const campaigns = await new Promise(resolve => {
    //     readCampaignsByHostId(hostId, campaigns => {
    //       resolve(campaigns)
    //     })
    //   })
    //   return campaigns
    // } catch (error) {
    //   throw new Error('Error fetching campaigns: ' + error)
    // }
  }

  const fetchMyProducts = () => {
    readProductsByHostId(hostId, products => {
      dispatch(setProducts(products))
    })
  }

  return {
    fetchAllMyEvents,
    fetchAllMyEventOrders,
    fetchMySpecificDateOrders,
    fetchMyCampaigns,
    fetchMyProducts,
  }
}
