import { GoPlus } from '@react-icons/all-files/go/GoPlus'
import { MdOutlineQrCodeScanner } from '@react-icons/all-files/md/MdOutlineQrCodeScanner'
import ThemeToggler from 'components/molecules/ThemeToggler'
import {
  ExternalLink,
  ICalendar,
  IChevronDown,
  IDiscount,
  IMembership,
  ITag,
  IVenues,
} from 'consts/Icons'
import ROUTES from 'consts/Routes'
import { getBaseUrlFor } from 'utils/Url'

const HEADER = {
  APP_USER_NAVIGATION: [
    // {
    //   title: 'Host Events',
    //   path: ROUTES.HOSTING.HOST_YOUR_EVENT,
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: 'Explore',
    //   path: ROUTES.EXPLORE,
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: 'Tickets',
    //   path: ROUTES.USER_TICKETS,
    //   className: 'hidden lg:block',
    // },
    // {
    //   path: '',
    //   title: 'Separator',
    //   className: 'hidden lg:block',
    // },
    // {
    //   title: '',
    //   path: ROUTES.DASHBOARD,
    //   className: 'btn btn-sm btn-secondary',
    //   icon: (
    //     <BsArrowLeftRight
    //       size={16}
    //       className='custom-transition-a cursor-pointer hover:fill-secondary'
    //     />
    //   ),
    // },
  ],
  APP_HOST_NAVIGATION: [
    {
      path: ROUTES.SCAN,
      icon: (
        <MdOutlineQrCodeScanner
          size={28}
          className='custom-transition-a cursor-pointer hover:fill-secondary'
        />
      ),
      className: 'hidden md:block',
    },
    {
      title: '',
      path: '#',
      icon: <GoPlus className='h-6 w-6 md:h-6 md:w-6' />,
      className: 'btn btn-sm btn-outline !p-0',
      subMenu: [
        {
          title: 'Event',
          path: ROUTES.EVENT_NEW,
          icon: <ICalendar size={16} />,
        },
        {
          title: 'Event (Using URL)',
          onClick: () => document.getElementById('event-creation-via-url-modal')?.showModal(),
          icon: <ExternalLink size={16} />,
        },
        {
          title: 'Venue',
          path: ROUTES.VENUE_ADD,
          icon: <IVenues size={16} />,
        },
        {
          title: 'Discount',
          path: ROUTES.DISCOUNT_NEW,
          icon: <IDiscount size={16} />,
        },
        {
          title: 'Merch *',
          path: ROUTES.PRODUCT_NEW,
          icon: <ITag size={16} />,
        },
        {
          title: 'Membership *',
          path: ROUTES.MEMBERSHIP_NEW,
          icon: <IMembership size={16} />,
        },
      ],
    },
    {
      title: '',
      path: '#',
      icon: <ThemeToggler showLabel={false} />,
    },
    // {
    //   title: 'Notification',
    //   path: ROUTES.DASHBOARD,
    //   icon: (
    //     <INotification
    //       size={24}
    //       className='custom-transition-a cursor-pointer hover:fill-secondary'
    //     />
    //   ),
    // },
  ],
  NAVIGATION_CENTER: [
    {
      title: 'About',
      path: ROUTES.ABOUT_US,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Pricing',
      path: ROUTES.FEATURES.PRICING_COMPARISON,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'FAQs',
      path: ROUTES.FAQS,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Blog',
      externalPath: getBaseUrlFor('marketplace') + ROUTES.RESOURCES.BLOG,
      className: 'hidden lg:block btn btn-sm btn-ghost hover:bg-base-200 custom-transition-b',
    },
    {
      title: 'Features',
      path: '#',
      className: 'hidden lg:block',
      icon: <IChevronDown size={16} />,
      subMenu: [
        {
          title: 'Discover',
          path: ROUTES.FEATURES.DISCOVER,
        },
        {
          title: 'Ticketing',
          path: ROUTES.FEATURES.TICKETING,
        },
        {
          title: 'Attendee Management',
          path: ROUTES.FEATURES.ATTENDEE_MANAGEMENT,
        },
        {
          title: 'Growth & Marketing',
          path: ROUTES.FEATURES.GROWTH_AND_MARKETING,
        },
      ],
    },
  ],
  NAVIGATION_RIGHT: [
    {
      title: '',
      path: '#',
      icon: <ThemeToggler showLabel={false} />,
    },

    {
      title: 'Log In',
      path: ROUTES.LOG_IN,
      className: 'btn btn-sm btn-primary',
    },
    {
      title: 'Sign Up',
      path: ROUTES.SIGN_UP,
      className: 'btn btn-sm btn-secondary',
    },
  ],
}

export default HEADER
